&.service-overview,
&.service-category-detail,
&.service-detail {
    .gallery-section + .content-section {
        padding-top: 1rem;
    }

    .content-section {
        .wysiwyg {
            font-size: $font-size-base;
        }

        + .content-section {
            padding-top: 0;
        }
    }

    .collection.grid {
        .grid-items {
            .item {
                @include media-breakpoint-up(sm) {
                    @include make-col(6);
                }

                .card-caption {
                    .card-title,
                    .card-title-link {
                        aspect-ratio: 3 / 2;
                    }
                }
            }
        }
    } 
}

&.service-category-detail {
    .footer {
        background-color: $beige-light;
    } 
}

&.service-detail {
    .footer {
        background-color: $dark;
    } 
}