.owl-carousel {
    --w3-owl-caption-offset: 0;
    z-index: 2;
    
    .item {
        position: relative;
        align-items: flex-end;

        .owl-caption-holder {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 5rem;
        }

        .owl-caption {
            @include prefix(transform, translateY( -100px ));
            @include prefix(transition, all 1s ease-out);
            opacity: 0;

            .owl-title {
                margin-bottom: .5rem;
                
                @include media-breakpoint-up(lg) {
                    margin-bottom: 1.5rem;
                }
            }
        }

        picture, img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
        }
    }

    &.video-collection {
        .item {
            padding: .5rem 0 !important;
            
            @include media-breakpoint-up(lg) {
                padding: 2rem 0 !important;
            }

            .owl-caption-holder {
                @include media-breakpoint-up(lg) {
                    bottom: 2rem;
                }
            }
        }
    }

    .owl-item.active,
    .owl-item.animated {
        .item {
            .owl-caption {
                @include prefix(transform, translateY( var(--w3-owl-caption-offset) ));
                opacity: 1;
            }
        }
    }

    .owl-video-tn {
        display: none;
    }

    .owl-video-play-icon {
        background: rgba($white, .8);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        margin-top: -25px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            width: 110px;
            height: 110px;
            margin-left: -55px;
            margin-top: -55px;
        }

        &:hover {
            @include prefix(transform, scale(1.07));
        }

        &::before {
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            z-index: -1;

            @include media-breakpoint-up(lg) {
                width: 46px;
                height: 46px;
            }
        }

        &::after {
            content: '\f144';
            font-family: $font-awesome;
            font-weight: 900;
            font-size: $h3-font-size;
            color: $primary;
            line-height: 0;

            @include media-breakpoint-up(lg) {
                font-size: $h1-font-size;
            }
        }
    }

    .owl-nav {
        .owl-prev, 
        .owl-next {
            font-size: $h4-font-size;
        }
    } 
}