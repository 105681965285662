.collection.grid {
    .grid-items {
        .item {
            z-index: 2;
        }
    }

    &.lead-content {
        position: relative;
        z-index: 2;

        // top right dashed line
        &::before {
            content: url('/images/svg/lead-top-mob.svg');
            position: absolute;
            z-index: 2;
            line-height: 0;
            left: 80%;
            bottom: 83%;
            
            @include media-breakpoint-up(lg) {
                content: url('/images/svg/lead-top.svg');
                left: 79%;
                bottom: 37%;
            }
        }

        // bottom left dashed line
        &::after {
            content: url('/images/svg/lead-bottom-mob.svg');
            position: absolute;
            line-height: 0;
            left: 10px;
            top: 50%;

            @include media-breakpoint-up(sm) {
                top: 37%;
            }
            
            @include media-breakpoint-up(lg) {
                content: url('/images/svg/lead-bottom.svg');
                left: auto;
                right: 85%;
                top: 85%;
            }
        }

        .grid-items {
            position: relative;
            flex-direction: column;
            margin-bottom: -1rem;
            z-index: 2;

            .item {
                flex: 0 0 auto;
                max-width: max-content;
                margin-bottom: 1rem;

                &:nth-child(1) {
                    align-self: center;
                }
                &:nth-child(2) {
                    align-self: flex-end;
                }
                &:nth-child(3) {
                    align-self: flex-start;
                }
            }
        }
    }

    &.masnory-collection {
        @include media-breakpoint-up(sm) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 7rem;
        }

        .grid-items {
            margin: 0 auto;
            position: relative;
            z-index: 1;
            display: block;
            column-count: 2;
            width: max-content;

            @include media-breakpoint-up(sm) {
                column-count: 3;
            }

            @include media-breakpoint-up(md) {
                column-count: 4;
            }

            @include media-breakpoint-up(lg) {
                display: grid;
                grid-gap: 1.25rem;
                grid-template-columns: 164px 164px 164px 164px 164px;
                grid-template-rows: minmax(150px, auto) minmax(150px, auto);
                grid-auto-rows: minmax(100px, auto);
                justify-content: unset;
                margin: 0;
            }

            .item {
                padding: 0;
                margin: 0 0 1.25rem;
                max-width: 164px;

                @include media-breakpoint-up(lg) {
                    margin: 0;
                }

                // column 1
                &:nth-child(1),
                &:nth-child(2) {
                    @include media-breakpoint-between(sm, md) {
                        transform: translateY(120px);
                    }

                    @include media-breakpoint-up(lg) {
                        transform: translateY(100px);
                    }
                }

                // column 3
                // on mobile
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    @include media-breakpoint-only(sm) {
                        transform: translateY(100px);
                    }
                }

                // on tablets
                &:nth-child(5),
                &:nth-child(6) {
                    @include media-breakpoint-only(md) {
                        transform: translateY(120px);
                    }
                }

                // on desktop
                &:nth-child(5),
                &:nth-child(6) {
                    @include media-breakpoint-up(lg) {
                        transform: translateY(60px);
                    }
                }

                // items
                &:nth-child(1) {
                    grid-column: 1;
                    grid-row: 1;
                    margin-top: 5rem;

                    @include media-breakpoint-up(sm) {
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    grid-column: 1;
                    grid-row: 2;
                }

                &:nth-child(3) {
                    grid-column: 2;
                    grid-row: 1;
                    align-self: end;
                }

                &:nth-child(4) {
                    grid-column: 2;
                    grid-row: 2;
                }

                &:nth-child(5) {
                    grid-column: 3;
                    grid-row: 1;
                    align-self: end;
                }

                &:nth-child(6) {
                    grid-column: 3;
                    grid-row: 2;
                }

                &:nth-child(7) {
                    
                    @include media-breakpoint-up(lg) {
                        grid-column: 4;
                        grid-row: 1;
                        transform: translateY(40px);
                        grid-row: 2;
                        align-self: end;
                    }
                }

                &:nth-child(8) {
                    
                    @include media-breakpoint-up(lg) {
                        grid-column: 4;
                        grid-row: 2;
                        grid-row: 2;
                        grid-column: 5;
                        transform: translateY(100px);
                        align-self: end;
                    }
                }
            }
        }

        &::before {
            content: '';
            background-image: url('/images/svg/stippellijn-1.svg');
            background-repeat: no-repeat;
            display: block;
            width: 300px;
            height: 58px;
            position: absolute;
            right: 0;
            bottom: 21rem;

            @include media-breakpoint-up(sm) {
                right: -9rem;
                bottom: 44rem;
            }

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &::after {
            content: '';
            background-image: url('/images/svg/stippellijn.svg');
            background-repeat: no-repeat;
            display: block;
            width: $body-width;
            height: 300px;
            position: absolute;
            left: -6rem;
            top: 13rem;

            @include media-breakpoint-up(md) {
                left: -10rem;
            }
        }
    }

    &.two-columns {
        .grid-items {
            .item {
                @include media-breakpoint-up(sm) {
                    @include make-col(12);
                }

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                .card-image {
                    img {
                        aspect-ratio: 4 / 3;
                    }
                }

                // .card-image  {
                //     margin-bottom: 180px;
                //     padding-bottom: 20px;
                    
                //     @include media-breakpoint-up(lg) {
                //         margin-bottom: 100px;
                //     }
                // }

                // .card-caption {
                //     .card-title,
                //     .card-title-link {
                //         aspect-ratio: 3 / 2;
                //     }
                // }
            }
        }
    }
}