&.vacancy-vacancy-overview {
	//.lead-section
	.lead-section {
		&.sec-separator {
			&::before {
				z-index: 2;
			}
		}
	}

	// mini-filter
	.mini-filter {
		margin: 0 auto 2rem;
		width: max-content;
		padding: 12px 20px;
		border-radius: 99px;
		background: $white;
		box-shadow: 0px 3px 6px rgba($black, 0.15);

		.form-group {
			margin: 0;
		}

		label {
			display: none;
		}

		select {
			border: none;

			&:focus,
			&:hover {
				outline: none;
				background-color: lighten($link-color, 90%);
				cursor: pointer;
				box-shadow: none;
			}

			option {
				background-color: $white;
			}
		}

		input[type="submit"] {
			padding: .5rem 2rem;
			box-shadow: 0px 3px 6px rgba($black, 0.15);
		}
	}
}

&.vacancy-vacancy-detail {
	.subtitle {
		margin-bottom: 1rem;
		display: flex;
		align-items: flex-end;
		column-gap: .5rem;
		color: $tertiary;
		font-weight: 600;

		.icon {
			font-size: $h6-font-size;
			
			i {
				font-weight: 300;
			}
		}
	}
}
