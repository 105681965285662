// btn
.btn {
	font-family: $font-family-secondary;
	color: $white;
	border-radius: 99px;
	padding: .75rem 2rem;
	line-height: normal;
	font-size: $h6-font-size;

	&:hover {
		color: $white;
	}

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: darken($primary, 20%);
			border-color: darken($primary, 20%);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: $primary;
		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			background-color: darken($secondary, 25%);
			border-color: darken($secondary, 25%);
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $secondary;
		&:hover {
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-secondary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1.5rem;
	text-decoration: none;
	color: $tertiary;
	margin-bottom: 0 !important;
	font-weight: 600;

	&:hover {
		color: darken($tertiary, 50%);
	}

	&::before {
		content: "\f137";
		position: absolute;
		left: 0;
		font-weight: 300;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// btn-block
.btn-block {
	font-weight: 400 !important;
}
