@keyframes jump-shaking {
    0% { transform: translateX(-10px) }
    25% { transform: translateX(-10px) translateY(-9px) }
    35% { transform: translateX(-10px) translateY(-9px) rotate(17deg) }
    55% { transform: translateX(-10px) translateY(-9px) rotate(-17deg) }
    65% { transform: translateX(-10px) translateY(-9px) rotate(17deg) }
    75% { transform: translateX(-10px) translateY(-9px) rotate(-17deg) }
    100% { transform: translateX(-10px) translateY(0) rotate(0) }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.page-block.title,
.page-block.subtitle,
.page-block.wysiwyg,
.grid .card,
.video-collection,
.eyecatcher-label,
.footer-text:not(.mega-footer-text),
.contact-list,
.footer-logolink,
.gallery-item,
.form {
    &:not(.animate__fadeInUp) {
        opacity: 0;
    }
}