&.accommodation-category-detail,
&.accommodation-detail {

    .lead-section.sec-separator::before {
        z-index: 2;
    }

    .gallery-section + .content-section {
        padding-top: 1rem;
    }

    .content-section {
        .wysiwyg {
            font-size: $font-size-base;
        }

        + .content-section {
            padding-top: 0;
        }
    }

    .collection.grid {
        .grid-items {
            .item {
                @include media-breakpoint-up(sm) {
                    @include make-col(6);
                }

                .card-caption {
                    .card-title,
                    .card-title-link {
                        aspect-ratio: 3 / 2;
                    }
                }
            }
        }
    }
}

&.accommodation-search-book {

    .page-title {
        text-align: center
    }

    .content-section {
        padding-top: 0;

        .container {
            @extend .container-wide;
        }
    }

    #trengo-web-widget,
    .w3-cookie-consent-widget {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

}

&.accommodation-detail {
    .lead-section {
        .container-one-column {
            .container-holder {
                max-width: unset;
                margin-right: -15px;
                margin-left: -15px;
            }
        }
    }
}

&.accommodation-book {
    padding-top: 0;

    .header {
        padding-left: 15px;
        position: relative;
        .logo {
            position: static;
        }
    }

    .main {
        min-height: 100vh;
    }

    .lead-section + .content-section {
        padding-top: 0;
    }

    .content-section {
        .container-holder {
            .column {
                z-index: 2;
            }
        }
    }
}