// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: $trans-duration;
	max-width: $body-width;
	margin-left: auto;
	margin-right: auto;

	&.sticky {
		// box-shadow: $shadow;
		filter: drop-shadow($shadow);
	}

	// section separator
	@include sec-sep("after") {
		bottom: -10px;
		z-index: -2;
		transition: $trans-duration ease-in;

		@include media-breakpoint-up(xl) {
			bottom: -10px;
		}
	}

	.container,
	.container-fluid {
		&:not(.mega-menu-container) {
			.container-holder {
				padding-bottom: 10px;
				margin-bottom: 0;

				> .column {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.container-holder {
			> .column {
				flex: 0 0 max-content;
				max-width: none;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;

				&.three {
					transition: 0.3s;
					margin-top: 10px;
					margin-left: auto;
					row-gap: 10px;
					flex-direction: column;
					padding-right: 0.875rem;
					z-index: 4;

					@include media-breakpoint-up(xl) {
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: flex-end;
						align-items: center;
						flex-basis: 65%;
					}

					* {
						justify-content: flex-end;
					}

					.searchbox {
						position: relative;
						z-index: 20;
						margin: 0 0 0 20px;
						overflow: hidden;

						.form-control {
							position: relative;
							width: 0;
							right: -100%;
							transition: $transition-base;

							&.show {
								width: auto;
								right: 0;
							}
						}

						.search-icon {
							position: absolute;
							top: 0;
							right: 0;
							display: block;
							min-width: 34px;
							height: 34px;
							margin: 2px 0 2px 15px;
							line-height: 34px;
							text-align: center;
							transition: $transition-base;
							cursor: pointer;

							i {
								font-weight: 400;
							}

							&.hide {
								right: -100%;
							}

							&:hover {
								color: $pink;
							}
						}
					}

					.menu {
						@extend .d-none;
						@extend .d-xl-block;
					}

				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		position: relative;
		z-index: 1;

		.navbar-toggler {
			@extend .d-flex;
			background-color: $primary;
			color: $white;
			align-items: center;
			border-radius: 0 0 5px 0;
			padding: 0.75rem 0.875rem;

			@include hover {
				background-color: darken($primary, 20%);
			}

			@include media-breakpoint-up(xl) {
				padding: 0.75rem 1.5rem 0.75rem 1.25rem;
			}

			&-icon {
				font-size: 1.5rem;
				text-align: left;

				i {
					width: 24px;
				}
			}
			&-label {
				font-size: $small-font-size;
				font-weight: 700;
				margin-left: 1.25rem;
				display: none;

				@include media-breakpoint-up(xl) {
					display: block;
				}
			}

			&[aria-expanded="true"] {
				.fa-align-left {
					display: none;
				}
			}

			&[aria-expanded="false"] {
				.fa-times {
					display: none;
				}
			}
		}
	}

	// logo
	.logo {
		margin: 20px 0 0;
		position: absolute;
		width: 200px;
		left: -40px;
		transition: $trans-duration;

		// xxs screens
		@media screen and (min-width: 360px) {
			left: -14px;
		}

		@include media-breakpoint-up(xl) {
			position: static;
			width: 235px;
			margin: 10px 7px 10px 20px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		&:not(.mega-menu) {
			@extend .order-xl-1;
		}

		.navbar-nav {
			> .nav-item {
				> .nav-link {
					font-weight: 700;
					font-size: $small-font-size;
				}
				&.active {
					> .nav-link:not([href="javascript:void(0);"]) {
						color: $tertiary;
					}
				}
			}
		}

		.nav-link {
			.menu-icon {
				margin-right: 5px;
			}
		}

		@include media-breakpoint-down(lg) {
		}

		@include media-breakpoint-up(xl) {
			flex-basis: unset !important;
			flex-grow: 0;
		}
	}

	// site-switcher
	.site-switcher {
		@extend .order-xl-3;
		@extend .col-xl-9;
		@extend .px-0;

		&.desktop-site-switcher {
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}

		&.mobile-site-switcher {
			@include media-breakpoint-up(sm) {
				display: none;
			}

			.navbar-nav.language {
				.dropdown {
					&-toggle {
						display: flex;
						align-items: center;
						flex-direction: row-reverse;

						&:hover,
						&:focus-visible {
							box-shadow: none;
							outline: none;
						}

						img {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		@extend .order-xl-4;
		margin-left: auto;
		max-width: max-content;

		@include media-breakpoint-up(lg) {
			flex-grow: 1;
			margin-left: 14px;
		}

		a {
			@extend .btn;
			display: inline-block;
			background-color: $secondary;
			padding: 0.5rem 1.25rem !important;

			@include media-breakpoint-up(xl) {
				padding: 0.5rem 1.5rem !important;
			}

			@include hover {
				background-color: darken($secondary, 40%);
				color: $white;
			}
		}
	}

	// account-button
	div.account-button {
		@extend .order-xl-2;
		margin-left: 1.5rem;

		.account-button {
			a {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				text-decoration: none;
				color: $tertiary;
				font-size: $small-font-size;

				@include hover {
					color: darken($tertiary, 20%);
				}

				i {
					font-size: $h6-font-size;
					line-height: normal;
				}

				.list-item-title {
					margin-right: 10px;
					display: none;

					@include media-breakpoint-up(xl) {
						display: block;
					}
				}
			}
		}
	}
}

&.mega-menu-opened {
	.header {
		&::after {
			opacity: 0;
		}

		.container-fluid {
			.container-holder {
				.column {
					&.three {
						@include media-breakpoint-down(lg) {
							row-gap: 1rem;
						}
					}
				}
			}
		}

		.mega-menu-holder {
			#default_menu {
				@extend .d-block;
				@extend .d-xl-none;
				margin-top: -2.25rem;

				.navbar-nav {
					flex-direction: row;
					justify-content: flex-end;

					> .nav-item {
						margin-right: 0.875rem;
					}
				}
			}
		}
	}
}
