// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3.5rem 0;
	}
}

// lead-section
// =========================================================================

&:not(.home) {
	.lead-section {

		.container-one-column {
			.container-holder {
				max-width: 880px;
				margin: 0 auto;
			}
		}
	}
}

.lead-section {
	padding: 3.5rem 0;
	overflow-x: clip;

	@include media-breakpoint-up(lg) {
		overflow: unset;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 1rem;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;
			column-gap: 1rem;

			li {
				color: $tertiary;
				font-weight: 600;
			}

			.icon {
				margin-right: 5px;
				font-size: $h6-font-size;

				i {
					font-weight: 300;
				}
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	padding: 3.5rem 0;
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3.5rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

.lead-section + .collection-section {
	padding-top: 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3.5rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

.lead-section + .bundle-overview-section {
	padding-top: 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3.5rem 0;
}

// gallery-section
// =========================================================================
// .gallery-section {}

// booking-section
// =========================================================================
.booking-section {
	padding: 3.5rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3.5rem 0;
}

// carousel-section
// =========================================================================
.carousel-section {
	padding: 0 0 3.5rem;

	.owl-carousel,
	.owl-carousel .item {
		aspect-ratio: 3 / 2;
		min-height: unset;
		width: 100%;

		@include media-breakpoint-up(lg) {
			aspect-ratio: 16 / 9;
		}

		.owl-caption {
			.owl-title {
				@include media-breakpoint-down(lg) {
					font-size: $h6-font-size;
				}
			}

			.owl-btn {
				@include media-breakpoint-down(lg) {
					font-size: $small-font-size;
				}
			}
		}
	}
}
