// mini-sab
.mini-sab {
	background-color: $light;
	padding-top: 4.5rem;

	@include media-breakpoint-up(lg)  {
		padding-top: 0;
		margin-bottom: 1.25rem;
	}

	.container {
		max-width: 1210px;
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			flex: 1;
			z-index: 100;
			position: relative;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
			}

			// .column {
			// 	@include media-breakpoint-down(sm) {
			// 		padding: 0;
			// 	}
			// }
		}
	}

	// tabs
	div.mini-sab-tabs {
		--w3-items-height: 43px;
		position: absolute;
		top: calc(0px - var(--w3-items-height)); // mini-search-book padding - items height
		left: 2.5rem;
		right: 2.5rem;

		ul {
			justify-content: center;
			flex-wrap: nowrap !important;

			li {
				margin-right: 4px;
				order: 0;

				@include media-breakpoint-down(md) {
					margin-right: 3px;
				}

				@include media-breakpoint-down(sm) {
					flex: 1;
				}

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					height: var(--w3-items-height);
					padding: 0 1.25rem;
					background-color: $sugar;
					border-bottom: 1px solid $white;
					border-radius: 4px 4px 0 0;
					color: $body-color;
					font-size: 10px;
					text-decoration: none;
					font-family: $headings-font-family;
					white-space: nowrap;

					@media screen and (min-width: 390px) {
						font-size: 12px;
					}
				}

				&:first-child {
					a {
						background-color: $white;
						color: $tertiary;

						&:hover {
							border-color: $primary;
							border-bottom: 1px solid $white;
						}

					}
				}
			}

		}
	}

	&.sec-separator {
		&::before {
			z-index: 2;
		}
	}
}

// hide mini sab
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail,
&.accommodation-search-book {
	.mini-sab {
		display: none;
	}
}

// lazy load mini-search-book button
.toggle-mini-sab-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-radius: 5px;
	background: #ffffff;
	box-shadow: 0 3px 6px #00000075;
	transition: .3s background-color linear;
	padding: 1rem 1.5rem;

	.btn-toggle-mini-sab {
		@extend .btn;
		@extend .btn-primary;
		flex-grow: 1;
	}
}
