.page-block {
	&.searchbox {
		margin: 0 0 15px 0;
		@include media-breakpoint-down(lg) {
			order: 1;
		}
		.form-control {
			border-color: $green;
			border-width: 2px;
			border-radius: 99px;
			color: $green;
			font-weight: 600;

			&:focus {
				border-color: $green;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgba($green, 0.25);
			}
		}

		.search-icon {
			display: none;
			color: $green;
			@include media-breakpoint-down(lg) {
				text-align: right !important;
			}
		}
	}

	&.search-results {
		.card{
		.card-title-link, .card-title{
			font-size: 20px;
		}
			&:not(:has(.card-image)){
				.card-title-link,
				.card-title {
					color: $black;
					position: static;
					padding: 0;
					&:hover {
						color: $primary;
					}
				}
				.card-description::before{
					display: none!important;
				}
			}
			&:not(:has(.card-description)){
				.card-title-link,
				.card-title {
					color: $black;
					position: static;
					padding: 0.5rem;
					&:hover {
						color: $primary;
					}
				}
			}
		}
		.search-item {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 30px 0;

			.search-term {
				@extend .btn;

				background: $gray-500;
				border: 1px solid $gray-500;

				user-select: auto;
				cursor: auto !important;
				color: $white;

				&:hover {
					background: $gray-500;
					border: 1px solid $gray-500;

					color: $white;
				}
			}
		
		}

		.search-alert {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 30px 0;
		}
	}
}

&.search {
	.content-section {
		.page-block {
			&.searchbox {
				margin: 0 0 15px 0;
				@include media-breakpoint-down(lg) {
					order: -1;
				}
				.form-control {
					border-color: $green;
					border-width: 2px;
					border-radius: 99px;
					color: $green;
					font-weight: 600;
					padding: 20px;
					width: 25%;
					@include media-breakpoint-down(lg) {
						width: 100%;
						padding: 10px;
					}
				}
			}
		}
	}
}
