// footer
.footer {
    position: relative;
    overflow-x: clip;
    background-color: $beige-light;

    // section separator
    @include sec-sep {
        top: -34px;
        @include prefix( transform, rotate(180deg) );
    }

    .footer-sitelinks {
        background-color: $light;
        padding: 150px 0 50px;

        .contact-list {
            .contact-list {
                flex-direction: column;
                margin-bottom: 1.5rem;
                li {
                    a {
                        color: $primary;
                        text-decoration: none;

                        .list-item-title {
                            font-family: $headings-font-family;
                            margin-left: 12px;
                            font-size: $h6-font-size;
                        }

                        &:hover {
                            color: darken($primary, 20%);
                        }
                    }
                }
            }
        }

        .footer-text {
            p {
                font-size: $h6-font-size;
            }
        }

        .footer-logolink {
            margin-top: 2rem;
            .list {
                column-gap: 1.5rem;
                justify-content: center;
                
                &-item {
                    font-size: $h3-font-size;
                    line-height: 1;

                    a {
                        display: block;
                        width: $h3-font-size;
                        color: $secondary;
                        
                        &:hover {
                            color: darken($secondary, 25%);
                        }
                    }
                }
            }
        }
    }

    .footer-copyright {
        @extend .bg-white;
        padding: 30px 10px 90px;

        .one {
            @include media-breakpoint-down(xs) {
                order: 1;
            }

            @include media-breakpoint-up(sm) {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }

        .two {
            @include media-breakpoint-down(xs) {
                margin-bottom: 50px;
            }

            @include media-breakpoint-up(sm) {
                flex: 0 0 40%;
                max-width: 40%;
            }
        }

        .list {
            column-gap: 2rem;

            @include media-breakpoint-down(xs) {
                justify-content: center;
            }

            &-item {
                font-size: $small-font-size;

                @include media-breakpoint-down(xs) {
                    font-size: 11px;
                }

                a {
                    &:hover {
                        color: $tertiary !important;
                    }

                    &[href="javascript:void(0)"] {
                        text-decoration: none !important;
                    }
                }
            }

        }

        div.footer-logos {
            .footer-logos {
                justify-content: center;
                align-items: center;
                column-gap: 1rem;
                
                @include media-breakpoint-up(sm) {
                    justify-content: flex-end;
                }
    
                li {
                    img {
                        max-height: 36px;
                    }
    
                    .list-item-title {
                        display: none;
                    }
                }
            }
        }
    }
}
