.form,
.review-form form {
    background-color: $white;
    border: unset;
    box-shadow: 0 0 .5rem rgba($black, .29);

    @include hover {
        box-shadow: 0 0 .5rem rgba($black, .5);
    }

    textarea {
        min-height: 150px;
    }
}