.card {
    border-radius: .5rem;
    border: unset;
    box-shadow: 0 0 .5rem rgba($black, .29);

    @include hover {
        box-shadow: 0 0 .5rem rgba($black, .5);
    }

    &.icon {
        @extend .h-auto;
        padding: 1.25rem 1.125rem;
        align-items: flex-start;
        background-color: $sugar;

        .card-image {
            flex: 0 0 auto;
            max-width: unset;
            margin-right: 1rem;
            margin-bottom: 1.25rem;
            font-size: $h1-font-size;
            line-height: 0;
            padding-top: 1px;
            
            &, &-link {
                color: $orange;
            }
        }

        .card-body {
            padding: 0;
            flex: 1 1 auto;
            max-width: unset;
        }

        .card-caption {
            .card-title-link,
            .card-title,
            .card-subtitle {
                color: $tertiary;
                font-size: $h6-font-size;
                font-family: $font-family-base;
                margin-bottom: 0;
                line-height: 1.5rem;
            }

            .card-title {
                font-weight: 700;
                margin-bottom: 0.25rem;
            }

            .card-subtitle {
                font-weight: 600;
            }

            .card-description {
                font-size: $small-font-size;
                p {
                    margin-bottom: 0;
                    color: $headings-color;
                }
            }
        }

        .card-buttons {
            display: none;
        }

        &.card-wide {
            padding: 1rem 1.5rem;
            align-items: center;

            .card-image {
                font-size: $h2-font-size;
                margin-bottom: 0;
            }

            .card-caption {

                .card-title {
                    font-weight: 900;
                    margin-bottom: 0;
                }
            }
    
        }
    }

    &:not(.icon) {

        .card-image {
            &::after {
                content: '';
                display: block;
                background-color: rgba($black, .25);
                position: absolute;
                inset: 0;
            }
        }

        .card-body {
            padding: 0;

            &:has(.card-description) {
                padding: 1.75rem 1.5rem;
            }
        }

        .card-label {
            @include label($orange, 138px, 140px);
            position: absolute;
            right: 1rem;
            bottom: 105%;
            justify-content: center;

            i {
                font-size: $h4-font-size;
            }

            b, strong {
                font-family: $headings-font-family;
                font-size: $h6-font-size;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .card-caption {
            display: flex;
            flex-direction: column;
        }

        .card-title-link,
        .card-title {
            color: $white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            &:hover {
                color: $white;
            }
        }

        .card-title {
            font-size: $h3-font-size;
            word-wrap: normal;
            padding: 1.75rem 1.5rem;

            @include media-breakpoint-up(sm) {
                font-size: $h2-font-size;
            }
        }

        .card-subtitle {
            display: none;
        }

        .card-description {
            height: 100%;
            position: relative;
            background-color: $white;
            z-index: 1;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            align-content: space-between;

            @include sec-sep {
                @include prefix(transform, rotate(180deg));
                top: -3rem;
                left: -1.5rem;
                right: -1.5rem;
            }

            &-content {
                flex: 0 0 100%;
                max-width: 100%;
                z-index: 1;
            }

            .list {
                margin-top: 0;
                display: flex;
                align-items: center;
                gap: 1.5rem;

                li {
                    color: $tertiary;
                    font-weight: 600;

                    &:nth-child(n + 3) {
                        display: none;
                    }

                    .icon {
                        font-size: $h6-font-size;
                        
                        i {
                            font-weight: 300;
                        }
                    }
                }
            }

            .icon-list {
                margin: 0;
                padding-left: 1.125rem;
            }

            .card-extra-info {
                display: flex;
                align-items: flex-end;
                column-gap: .5rem;
                color: $tertiary;
                font-weight: 600;

                .icon {
                    font-size: $h6-font-size;
                    
                    i {
                        font-weight: 300;
                    }
                }
            }
        }

        .card-buttons {
            margin-top: 0;

            .card-btn {
                @extend .btn-secondary;
                color: $white;
            }
        }
    }

    &.card-overlay {

        // .card-image {
        //     position: absolute !important;
        //     left: 0; right: 0;
        //     top: 0;
        //     z-index: -1;
        // }

        .card-label {
            @include label($orange, 138px, 140px);
            position: absolute;
            right: 1rem;
            bottom: 90%;
            justify-content: center;

            i {
                font-size: $h4-font-size;
            }

            b, strong {
                font-family: $headings-font-family;
                font-size: $h6-font-size;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .card-img-overlay {
            padding: 0;
            position: static;
            height: 100%;
            overflow: visible;
        }

        .card-caption {
            height: 100%;
            display: flex;
            flex-direction: column;
            color: $black;
            text-shadow: none;

            .card-title,
            .card-description {
                padding: 1.75rem 1.5rem;
            }

            .card-title,
            .card-title-link {
                aspect-ratio: 1;
                margin-bottom: -10px;
            }

            .card-title {
                color: $white;
                font-size: $h3-font-size;
                word-wrap: normal;
                @include media-breakpoint-up(sm) {
                    font-size: $h2-font-size;
                }
            }

            .card-subtitle {
                display: none;
            }

            .card-description {
                flex-grow: 1;
                position: relative;
                // margin-top: auto;
                background-color: $white;
                z-index: 1;
                min-height: 120px;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                align-content: space-between;

                @include sec-sep {
                    top: -16px;
                    right: -1px;
                    z-index: -1;
                    @include prefix(transform, rotate(180deg));
                }

                &-content {
                    flex: 0 0 100%;
                    max-width: 100%;

                    p {
                        margin-bottom: 0;
                    }
                }

                .list {
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;

                    li {
                        color: $tertiary;
                        font-weight: 600;

                        &:nth-child(n + 3) {
                            display: none;
                        }

                        .icon {
                            font-size: $h6-font-size;
                            
                            i {
                                font-weight: 300;
                            }
                        }
                    }
                }

                .icon-list {
                    margin: 0;
                    padding-left: 1.125rem;
                }

                .card-extra-info {
                    display: flex;
                    align-items: flex-end;
                    column-gap: .5rem;
                    color: $tertiary;
                    font-weight: 600;

                    .icon {
                        font-size: $h6-font-size;
                        
                        i {
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .card-buttons {
            margin-top: 0
            ;
            .card-btn {
                @extend .btn-secondary;
                color: $white;
            }
        }

    }


    .card-prices {
        font-size: $small-font-size;

        .card-price {
            font-weight: 400 !important;
        }

        .forsale {
            text-decoration: line-through;
            font-size: 12px;
            color: $tertiary;
        }
    }
   
}