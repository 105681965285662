// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	position: relative;
	align-items: center;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;

	@include media-breakpoint-up(sm) {
		height: 100vh;
		max-height: 42vh;
	}

	@include media-breakpoint-up(lg) {
		max-height: 71.5vh;
	}

	.owl-container {
		@extend .container-large;

		padding-left: 30px;
		padding-right: 30px;
	}

	.owl-caption-holder {
		bottom: 9rem;
	}

	.owl-caption .owl-title {
		font-size: $h2-font-size;
		
		@include media-breakpoint-up(xl) {
			font-size: $h1-font-size;
			margin-top: 5rem;
		}
	}
}

.anwb-label {
	position: absolute;
	right: 1rem;
	top: 3rem;
	z-index: 2;
	max-width: 115px;

	@include media-breakpoint-up(sm) {
		max-width: unset;
		right: 2rem;
	}
}

div.eyecatcher-label {
	position: absolute;
	bottom: -1rem;
	right: 1rem;
	z-index: 3;
	
	@include media-breakpoint-up(lg) {
		right: 3rem;
		bottom: 15%;
	}

	.eyecatcher-label {
		li {
			@include label($blue-light, 150px, 153px);

			@include media-breakpoint-up(lg) {
				width: 210px;
				height: 213px;
			}
			
			a {
				padding-top: 1.5rem;
				display: flex;
				flex-flow: column wrap;
				align-items: center;
				text-decoration: none;
				flex: 1;

				@include media-breakpoint-up(lg) {
					padding-top: 1.875rem;
				}
			}

			i {
				color: $tertiary;
				font-size: $h3-font-size;
				margin-bottom: 5px;
				animation: jump-shaking 0.83s infinite;

				@include media-breakpoint-up(lg) {
					font-size: $h2-font-size;
				}
			}

			.list-item-title {
				color: $link-color;
				font-family: $headings-font-family;
				font-size: $font-size-base;
				text-transform: uppercase;
				line-height: 1;
				margin-bottom: .5rem;
				padding-left: 1rem;
				padding-right: 1rem;
				width: 100%;

				br {
					margin-right: 10px;
				}

				@include media-breakpoint-up(lg) {
					font-size: $h4-font-size;
				}
			}

			.list-item-suffix {
				color: $tertiary;
				font-size: $small-font-size;
				line-height: 1;
				max-width: 60%;
			}
		}
	}
}