// header
.header {

	.mega-menu-holder {
		display: none;


		.mega-menu-container {
			.container-holder {
				padding: 0 30px;
		
				@include media-breakpoint-up(xl) {
					padding: 0 140px;
				}
			}
		}

		.container-holder {
			> .column {
				flex-basis: auto;
                z-index: 1;
			}
		}
	}

    .mega-menu-top {
        position: relative;
        background-color: $white;
        z-index: 1;

        // section separator
        @include sec-sep('after') {
            bottom: -18px;
        }
    }

    .mega-menu-top:has(.mega-menu-buttons:is(.d-none)) + .mega-menu-footer{
        @include media-breakpoint-down(sm) {
            padding: 3.5rem 0 2.25rem;
        }
    }

    .mega-menu-footer {
        position: relative;
        padding: 5.5rem 0 2.25rem;
        font-size: 12px;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            padding: 10.5rem 0 .5rem;
        }

        .container-holder {
			> .column {
                flex-flow: row wrap;
                column-gap: 2.5rem;
                
                @include media-breakpoint-down(lg) {
                    column-gap: 1.5rem;
                }

                > .page-block {
                    flex: 0 0 100%;
                    max-width: 100%;

                    @include media-breakpoint-up(md) {
                        flex: 0 0 auto;
                        max-width: 240px;
                    }

                    &.footer-text {
                        @include media-breakpoint-down(sm) {
                            flex: 0 0 auto;
                            max-width: 190px;
                            align-self: flex-end;
                        }

                        @media screen and (max-width: 376px) {
                            max-width: 175px;
                        }
                    }
                }
			}
		}

        .footer-text {

            &:last-of-type {
                margin-left: auto;
                text-align: right;
            }
        }

        .footer-list {
            h5 {
                @include media-breakpoint-down(sm) {
                    cursor: pointer;

                    &::after {
                        content: '\f0d7';
                        font-family: $font-awesome;
                        font-weight: 900;
                        margin-left: 10px;
                        color: $black;
                        font-size: 12px;
                    }
                }
            }
            
            .list {
                @extend .d-md-block;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                    display: none;
                }
            }

            &.show {
                h5 {
                    @include media-breakpoint-down(sm) {
                        &::after {
                            content: '\f0d8';
                        }
                    }
                }
            }
        }

        h5 {
            font-size: $small-font-size;
            color: $link-color;
            text-transform: uppercase;
        }

        a {
            color: $tertiary !important;
            text-decoration: none !important;
            font-weight: 700;

            @include hover {
                color: darken($tertiary, 50%) !important;
            }
        }

        p {
            margin-bottom: 10px;
        }
    }

	.mega-menu {
		@extend .d-block;
		flex-basis: 100% !important;

        @include media-breakpoint-up(xl) {
            padding: 1.5rem 0;
        }

		.navbar-nav {
			column-gap: 4rem;
			flex: 1;
            margin-top: 1rem;

			@include media-breakpoint-up(xl) {
				flex-direction: row;
                margin-top: 0;
			}

			> .nav-item {
				margin-bottom: .625rem;
                line-height: 1.25rem;

				> .nav-link {
					text-transform: uppercase;
					font-family: $font-family-secondary;
					font-weight: 400;
                    padding: 0;
					
					@include media-breakpoint-up(xl) {
                        font-size: $h6-font-size;
						pointer-events: none;
                        padding: 0 0 5px;
					}

					.menu-icon {
						color: $tertiary;
						font-size: $h5-font-size;
						vertical-align: middle;
						display: inline-block;
						width: 26px;
						
						@include media-breakpoint-up(xl) {
                            display: block;
							font-size: $h4-font-size;
                            margin-bottom: 5px;
						}
					}

					&::after {
						@include media-breakpoint-up(xl) {
							display: none;
						}
					}
				}
			}

			.dropdown-menu {
                @extend .d-xl-block;
				position: static !important;
				border: none;
				transform: none !important;
				padding: 0 0 0 31px;
				margin: 0;
				box-shadow: none;

				@include media-breakpoint-up(xl) {
					padding: 0;
				}

                &::before {
                    @extend .d-none;
                }

				.nav-link {
					padding-top: 0;
					padding-bottom: 0;
					font-size: $small-font-size;
					font-weight: 500;
                    color: $black;

					@include hover {
						color: $tertiary;
					}
				}

                .nav-item {
                    &.active {
                        > .nav-link:not([href="javascript:void(0);"]) {
                            color: $tertiary;
                        }
                    }
                }
			}
		}
	}

    div.mega-menu-buttons {
        position: relative;
        z-index: 1;
        margin-top: 4rem;
        margin-bottom: -146px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: -50px;
            margin-top: 1rem;
        }

        .mega-menu-buttons {
            gap: 10px;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            li {
                background-color: $sugar;
                box-shadow: 0 0 .5rem rgba($black, .29);
                border: none;
                border-radius: .5rem;
                padding: 8px 20px;
                @include prefix(transition, box-shadow ease $trans-duration);

                @include media-breakpoint-up(md) {
                    padding: 8px 12px;
                }

                &:hover {
                    box-shadow: 0 0 .5rem rgba($black, .5);
                }

                &,
                a {
                    text-decoration: none;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    flex: 0 0 100%;
                    max-width: 100%;
                    
                    @include media-breakpoint-up(md) {
                        flex: 0 0 220px;
                        max-width: 220px;
                    }
                }

                i {
                    color: $orange;
                    margin-right: 14px;
                    
                    @include media-breakpoint-up(md) {
                        margin-right: 10px;
                    }
                    
                    &:has(+ span + .list-item-suffix) {
                        @include prefix(transform, translateY(50%));
                    }
                }

                .list-item-title,
                .list-item-suffix {
                    font-family: $font-family-primary;
                    color: $tertiary !important;
                }

                .list-item-title {
                    font-size: $small-font-size;
                    font-weight: 700;
                    margin-bottom: 2px;
                    line-height: normal;
                }

                .list-item-suffix {
                    font-size: 12px;
                    font-weight: 500;
                    margin-left: 28px;
                    flex: 0 0 100%;

                    @include media-breakpoint-up(md) {
                        margin-left: 1.5rem;
                    }
                }
            }
        }
    }

    div.mega-menu-label {
        position: absolute;
        right: 10px;
        bottom: -45px;
        z-index: 1;
        
        @include media-breakpoint-up(xl) {
            right: 0;
            bottom: -65px;
        }

        .mega-menu-label {
            position: relative;

            &::before {
                content: url('/images/svg/dashed-road.svg');
                position: absolute;
                left: 20px;
                bottom: 55%;
                z-index: -1;
                @include prefix(transform, rotate(315deg));

                @include media-breakpoint-up(xl) {
                    left: 65%;
                    bottom: 5px;
                    transform: none;
                }
            }


            li {
                @include label($blue-light, 145px, 150px);

                @include media-breakpoint-up(xl) {
                    width: 155px;
                    height: 164px;
                }
                
                a {
                    padding-top: 1.25rem;
                    display: flex;
                    flex-flow: column wrap;
                    align-items: center;
                    text-decoration: none;
                    flex: 1;
                }

                i{
                    color: $tertiary;
                    font-size: $h2-font-size;
                    margin-bottom: 5px;
                }

                .list-item-title {
                    color: $link-color;
                    font-family: $headings-font-family;
                    font-size: 1.125rem;
                    text-transform: uppercase;
                    line-height: 1;
                    
                    @include media-breakpoint-up(xl) {
                        font-size: 1.25rem;
                    }
                }

                .list-item-suffix {
                    color: $tertiary;
                    font-size: 12px;
                    line-height: 1;
                    
                    @include media-breakpoint-up(xl) {
                        font-size: $small-font-size;
                    }
                }
            }
        }
    }
}