.witter-sun {
    position: relative;
    overflow-x: clip;

    &::after {
        content: url('/images/svg/witter-sun.svg');
        opacity: .4;
        position: absolute;
        bottom: 75%;
        z-index: 1;
    }

    &-right {
        &::after {
            right: -20rem;
        }
    }

    &-left {
        &::after {
            left: -20rem;
        }
    }

    &-top {
        &::after {
            bottom: auto;
            top: 0;
        }
    }

    &-bottom {
        &::after {
            bottom: 0;
        }
    }
}

.footer {
    &::after {
        content: url('/images/svg/witter-sun.svg');
        position: absolute;
        right: -20rem;
        bottom: 75%;
        opacity: .4;
    }
}