.gallery,
.gallery-horizontal {
    margin: 0 -1px -3px;

    .gallery-item {
        padding: 0 1px;
        margin: 0 0 2px 0;

        .gallery-img {
            @include media-breakpoint-up(lg) {
                aspect-ratio: 4 / 3;
            }
        }

        &.large-item {
            padding: 0;
            
            @include media-breakpoint-up(lg) {
                padding: 0 1px 0 0;
            }
        }
    }

    .gallery-label {
        top: 1.5rem;
        padding: 4px 8px;
    }
}

.gallery-grid,
.gallery {
    .gallery-item {
        border-radius: 10px;
        .gallery-link {
            border-radius: 10px;
        }
    }
}

.gallery-horizontal {

    .gallery-item {

        &.large-item {
            
            @include media-breakpoint-up(lg) {
                border-radius: 10px 0 0 10px;
            }

            .gallery-link {
                border-radius: 10px 10px 0 0;
                
                @include media-breakpoint-up(lg) {
                    border-radius: 10px 0 0 10px;
                }
            }
        }
    }

    .gallery-thumbs {
        .gallery-item {

            &.first-item {
                + .gallery-item {
                    .gallery-link {
                        @include media-breakpoint-up(lg) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                }
            }

            &.before-last-item {
                .gallery-link {
                    border-radius: 0 0 0 10px;

                    @include media-breakpoint-up(lg) {
                        border-radius: 0;
                    }
                }
            }

            &.last-item {
                .gallery-link {
                    border-radius: 0 0 10px 0;
                }
            }
        }
    }
}

.gallery-grid {
    margin: 0 -2px -4px -2px;
    .gallery-item {
        padding: 0 2px;
        margin-bottom: 4px;
        .gallery-link {
            .gallery-caption {
                display: none;
                // padding: 1.75rem 1.5rem;
                // color: $white;
                // font-size: $h3-font-size;
                // font-family: $headings-font-family;
                // word-wrap: normal;
                // @include media-breakpoint-up(sm) {
                //     font-size: $h2-font-size;
                // }
            }
        }
    }
}