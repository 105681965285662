// theme setup
@import '~framework/assets/scss/V2.1/0_config/_functions';
@import '~framework/assets/scss/V2.1/0_config/_mixins';
@import '~framework/assets/scss/V2.1/0_config/_custom-variables';
@import './0_config/variables';
@import './0_config/mixins';
@import './0_config/animations';
@import '~framework/assets/scss/V2.1/0_config/_variables';
@import '~framework/assets/scss/V2.1/1_vendor/bootstrap';

// or manually select bootstrap components:
/*
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
//@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
//@import '~bootstrap/scss/badge';
//@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
//@import '~bootstrap/scss/tooltip';
//@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';
*/


// force html font size (prevent tommybooking conflict)
html {
	@import "~framework/assets/scss/V2.1/5_utilities/reset";
	scroll-behavior: smooth;
	scroll-padding-top: 150px;

	max-width: $body-width;
	margin-left: auto;
	margin-right: auto;
}

// theme within body class
body {
	// 2_layout
	@import "~framework/assets/scss/V2.1/2_layout/containers";

	// 3_components
	@import "~framework/assets/scss/V2.1/3_components/alert";
	@import "~framework/assets/scss/V2.1/3_components/buttons";
	@import "~framework/assets/scss/V2.1/3_components/card";
	@import "~framework/assets/scss/V2.1/3_components/carousel";
	@import "~framework/assets/scss/V2.1/3_components/cookie-consent";
	@import "~framework/assets/scss/V2.1/3_components/faq";
	@import "~framework/assets/scss/V2.1/3_components/form";
	@import "~framework/assets/scss/V2.1/3_components/lists";
	@import "~framework/assets/scss/V2.1/3_components/loading";
	@import "~framework/assets/scss/V2.1/3_components/recaptcha";
	@import "~framework/assets/scss/V2.1/3_components/timeslot";

	// 4_page_block
	@import '~framework/assets/scss/V2.1/4_page_block/_all';

	// 5_utilities
	@import "~framework/assets/scss/V2.1/5_utilities/animations";
	// @import "~framework/assets/scss/V2.1/5_utilities/contextual";
	@import "~framework/assets/scss/V2.1/5_utilities/sticky";

	// app styling
	@import "./2_layout/base";
	@import "./2_layout/eyecatcher";
	@import "./2_layout/footer";
	@import "./2_layout/header";
	@import "./2_layout/mega-menu";
	@import "./2_layout/mini-sab";
	@import "./2_layout/sections";
	@import "./2_layout/pages";

	@import "./3_components/buttons";
	@import "./3_components/lists";
	@import "./3_components/cards";
	@import "./3_components/collections";
	@import "./3_components/owl-carousel";
	@import "./3_components/witter-sun";
	@import "./3_components/gallery";
	@import "./3_components/forms";
	@import "./3_components/google-reviews";
	@import "./3_components/widgets";

	@import "./4_page_block/collections_custom";

	// @import "./6_tommy/tommy.book";
	// @import "./6_tommy/tommy.matrix";
	// @import "./6_tommy/tommy.mini-search-book";
	// @import "./6_tommy/tommy.search-book";

	@import "./7_theme/module.accommodation";
	@import "./7_theme/page.contact";
	@import "./7_theme/module.service";
	@import "./7_theme/module.blog";
	@import "./7_theme/module.assortiment";
	@import "./7_theme/module.vacancy";
	@import "./7_theme/page.landingspage";
	@import "./7_theme/page.one-column-page";


	background-color: #EEE !important;
}
