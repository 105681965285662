// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #6CAE8F;
$green-dark: #3E514E;
$orange: #EB902D;
$red: #9E2823;
$beige: #E1D9C6;
$beige-light: #F5F3EB;
$blue-light: #ADD3D5;
$sugar: #FDFAF2;
$white: #fff;
$black: #000;

// theme-colors
$primary: $orange;
$secondary: $green;
$tertiary: $red;

// body
$body-bg: $beige-light;
$body-color: $black;

// links
$link-color: $green-dark;
$link-decoration: underline;
$link-hover-color: $tertiary;

// typography
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
$font-family-primary: "Figtree", sans-serif;
$font-family-secondary: "Lilita One", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$small-font-size: $font-size-base * 0.875; // 14px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $green-dark;

// contextual
$light: $beige;
$dark: $blue-light;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 0 .5rem rgba($black, 0.3);

// transitons
$trans-duration: 200ms;

// spaces
$body-width: 1920px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1070px,
  xxl: 1070px,
  xxxl: 1070px,
) !default;