// mixins

// adds vendor prefixes
@mixin prefix($property, $value) {
	$prefixes: webkit, moz, ms, o;
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;
	}
	#{$property}: $value;
}

// hover effect
@mixin hover($transition: true) {
    @if ( $transition ) {
        @include prefix(transition, $trans-duration);
    }

    &:hover {
        @content;
    }
}

// section separator
@mixin sec-sep($pos: 'before') {

    $pseudo: if($pos == 'before', 'before', 'after');

    &::#{$pseudo} {
        content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		height: 68px;
		background-color: inherit;
		mask-image: url('/images/svg/sec-separator.svg');
		mask-repeat: repeat-x;
        @content;
    }
}

// label component
@mixin label($bg: $white, $w: 100px, $h: 100px) {
	background-color: $bg;
	padding: 0;
	color: $white;
	mask-image: url('/images/svg/blob.svg');
	mask-size: contain;
	mask-repeat: no-repeat;
	width: $w;
	height: $h;
	text-align: center;
	display: flex;
	flex-direction: column;
	font-size: $small-font-size;
	line-height: 1;
	gap: .25rem;
}