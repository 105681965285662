&.disclaimer,
&.privacy-statement {
    .lead-section {
        padding-bottom: 2rem;
    }

    .lead-section + .content-section {
        padding-top: 0;

        .container {
            .container-holder {
                max-width: 880px;
                margin: 0 auto;
            }
        }
    }
}

&.landing-page {
    .container:has(.collection.grid) {
        @extend .container-wide;
    }

    .content-section,
    .collection-section {
        // padding-top: 0;
    }

    .card.card-overlay .card-caption .card-description-content {
        -webkit-line-clamp: unset;
        line-clamp: unset;
    }
}