&.default {
	padding-top: 110px;
	background-color: $beige-light;
}

& {
	&::before {
		content: '';
		display: block;
		position: fixed;
		inset: 0;
		background-color: rgba($black, .5);
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		@include prefix(transition, $trans-duration);
	}

	&.mega-menu-opened {
		&::before {
			opacity: 1;
			visibility: visible;
			z-index: 101;
		}
	}
}

main {
	background-color: $body-bg;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 750px;
	}

	&.container-intro {
		max-width: 750px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 750px;
		margin-top: 30px;
		text-align: center;
	}

	&.container-large {
		max-width: 1070px;
	}

	&.container-wide {
		max-width: 1290px;
		position: relative;
		z-index: 3;
	}

	.container-holder {
		> .column {
			z-index: 2;
			
			&.two {
				z-index: 3;
			}
		}
	}
}

// backgrounds
.bg-light-extra {
	background-color: $beige-light;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: $trans-duration;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// section separator
.sec-separator {
	position: relative;
	
	@include sec-sep {
		z-index: 1;
		top: -18px;
	}

	&-inverse {
		&::before {
			top: auto;
			bottom: -18px;
			@include prefix(transform, rotate(180deg));
		}
	}
}

// subtitle
.subtitle {
	font-family: $font-family-base;
	font-weight: 600;
	line-height: 1;
}

// text
.wysiwyg {
	font-size: $h6-font-size;
}

// float section
.extra-pb {
	padding-bottom: 13rem !important;

	& + .carousel-section {
		.owl-carousel {
			margin-top: -8rem;
			border-radius: 10px;
			overflow: hidden;
		}
	}

	@include media-breakpoint-up(md) {
		padding-bottom: 20rem !important;

		& + .carousel-section {
			.owl-carousel {
				margin-top: -16rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 25rem !important;

		& + .carousel-section {
			.owl-carousel {
				margin-top: -23rem;
			}
		}
	}
}

.extra-pb-gallery {
	&:has(+ .gallery-section) {
		padding-bottom: 20rem !important;
	}

	& + .gallery-section {
		.image-collection {
			margin-top: -22rem;
		}
	}

	@include media-breakpoint-up(lg) {
		&:has(+ .gallery-section) {
			padding-bottom: 17rem !important;
		}

		& + .gallery-section {
			.image-collection {
				margin-top: -19rem;
			}
		}
	}
}

// custom-pos
.custom-pos {
	position: relative;
	z-index: 2;

	&.container .container-holder > .column {
		margin-bottom: 2rem;
		
		@include media-breakpoint-up(xl) {
			position: absolute;
			width: max-content;
			right: -5.5rem;
			top: 7rem;
		}
	}
}


// default-card
// .default-card {}